package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.HeroSectionFontStyle
import com.binayshaw7777.myportfolio.HeroSectionLayoutStyle
import com.binayshaw7777.myportfolio.TextAnimationKeyframes
import com.binayshaw7777.myportfolio.components.widgets.ScrollDownAnimation
import com.binayshaw7777.myportfolio.components.widgets.SocialMediaIcons
import com.binayshaw7777.myportfolio.utils.Strings
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.s

@Composable
fun Hero() {
    Column(
        modifier = HeroSectionLayoutStyle.toModifier(),
        verticalArrangement = Arrangement.SpaceEvenly,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        val breakpoint = rememberBreakpoint()

        Spacer()

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.Top,
            horizontalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.Start
            ) {
                SpanText(
                    text = Strings.HI_IM,
                    modifier = HeroSectionFontStyle.toModifier()
                        .animation(
                            TextAnimationKeyframes.toAnimation(
                                duration = 1.s,
                                timingFunction = AnimationTimingFunction.EaseInOut
                            )
                        )
                )
                SpanText(
                    text = Strings.BINAY_SHAW,
                    modifier = HeroSectionFontStyle.toModifier()
                        .animation(
                            TextAnimationKeyframes.toAnimation(
                                duration = 1.s,
                                timingFunction = AnimationTimingFunction.EaseInOut
                            )
                        )
                )
                SpanText(
                    text = Strings.NICE_TO_MEET_YOU,
                    modifier = Modifier.fontSize(20.px)
                        .color(Color.rgb(0x909090))
                        .animation(
                            TextAnimationKeyframes.toAnimation(
                                duration = 1.s,
                                timingFunction = AnimationTimingFunction.EaseInOut
                            )
                        )
                )
            }
            Spacer()

            if (breakpoint >= Breakpoint.MD) {
                ScrollDownAnimation()
            }

        }
        Spacer()
        SocialMediaIcons()
    }
}