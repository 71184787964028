package com.binayshaw7777.myportfolio.components.widgets

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.Rotate
import com.binayshaw7777.myportfolio.utils.Res
import com.binayshaw7777.myportfolio.widgets.AppearanceAwareImage
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Span

@Composable
fun ScrollDownAnimation() {
    val color = if (ColorMode.current == ColorMode.LIGHT) Colors.Black else Colors.White

    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier.fillMaxHeight()
    ) {
        AppearanceAwareImage(
            src = Res.SCROLL_DOWN_CIRCULAR_TEXT,
            modifier = Modifier
                .animation(
                    Rotate.toAnimation(
                        null,
                        duration = 10.s,
                        timingFunction = AnimationTimingFunction.Linear,
                        iterationCount = AnimationIterationCount.Infinite
                    )
                )
        )
        Span(
            attrs = Modifier
                .margin(0.px, autoLength)
                .display(DisplayStyle.Block)
                .borderRadius(50.px)
                .border(3.px, LineStyle.Solid, color)
                .height(48.px)
                .width(28.px)
                .position(Position.Relative)
                .toAttrs()
        ) {
            Span(
                attrs = Modifier
                    .position(Position.Absolute)
                    .backgroundColor(color)
                    .borderRadius(50.percent)
                    .left(50.percent)
                    .transform { translateX((-50).percent) }
                    .animation(
                        Move.toAnimation(
                            duration = 2.s,
                            timingFunction = AnimationTimingFunction.EaseInOut,
                            iterationCount = AnimationIterationCount.Infinite
                        )
                    )
                    .size(12.px)
                    .toAttrs()
            )
        }
    }
}

val ScrollDownAnimationStyle = CssStyle.base {
    Modifier
        .position(Position.Absolute)
        .top(50.percent)
        .left(50.percent)
        .transform { translate((-50).percent, (-50).percent) }
}

val Move = Keyframes {
    0.percent {
        Modifier
            .transform { translate((-50).percent, 4.px) }
    }
    50.percent {
        Modifier
            .transform { translate((-50).percent, 24.px) }
    }
    100.percent {
        Modifier
            .transform { translate((-50).percent, 4.px) }
    }
}