package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.SectionTitleFontStyle
import com.binayshaw7777.myportfolio.utils.Res
import com.binayshaw7777.myportfolio.utils.Strings
import com.binayshaw7777.myportfolio.widgets.ExperienceBox
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px

@Composable
fun Experience() {

    val breakpoint = rememberBreakpoint()

    val imageSize = when (breakpoint) {
        Breakpoint.ZERO -> 40
        Breakpoint.SM -> 60
        Breakpoint.MD -> 75
        Breakpoint.LG -> 100
        else -> 100
    }

    Column(
        modifier = Modifier.fillMaxSize().margin(topBottom = 200.px).id("experience"),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.SpaceAround
    ) {
        SpanText(
            text = Strings.EXPERIENCE,
            modifier = SectionTitleFontStyle.toModifier()
        )
        HorizontalDivider()

        ExperienceBox(
            imageRes = Res.IONAGE_WORK_EXP,
            imageWidth = imageSize,
            imageHeight = imageSize,
            organizationName = Strings.IONAGE,
            jobTitle = Strings.SOFTWARE_DEVELOPER,
            duration = Strings.IONAGE_DURATION_FTE
        )

        HorizontalDivider()

        ExperienceBox(
            imageRes = Res.IONAGE_WORK_EXP,
            imageWidth = imageSize,
            imageHeight = imageSize,
            organizationName = Strings.IONAGE,
            jobTitle = Strings.SOFTWARE_DEVELOPER_INTERN,
            duration = Strings.IONAGE_DURATION_INTERN
        )

        HorizontalDivider()

        ExperienceBox(
            imageRes = Res.HUMARA_NAGAR_WORK_EXP,
            imageWidth = imageSize,
            imageHeight = imageSize,
            organizationName = Strings.HUMARA_NAGAR,
            jobTitle = Strings.ANDROID_DEVELOPER_INTERN,
            duration = Strings.HUMARA_NAGAR_DURATION
        )

        HorizontalDivider()

        ExperienceBox(
            imageRes = Res.EDVORA_WORK_EXP,
            imageWidth = imageSize,
            imageHeight = imageSize,
            organizationName = Strings.EDVORA,
            jobTitle = Strings.ANDROID_DEVELOPER_INTERN,
            duration = Strings.EDVORA_DURATION
        )

        HorizontalDivider()
    }
}