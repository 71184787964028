package com.binayshaw7777.myportfolio.widgets

import androidx.compose.runtime.*
import com.binayshaw7777.myportfolio.utils.Constants
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.AnimationTimingFunction.Companion.EaseInOut
import org.jetbrains.compose.web.dom.Span

val ScaleDownAnimation = CssStyle {
    base {
        Modifier.padding(leftRight = 20.px)
            .margin(top = 30.px)
            .transition(
                Transition.of(
                    property = TransitionProperty.All,
                    duration = 300.ms,
                    timingFunction = null,
                    delay = null
                )
            )
            .scale(1)
    }
    hover {
        Modifier.padding(leftRight = 30.px).scale(0.98)
    }
    Breakpoint.SM {
        Modifier.margin(top = 40.px)
    }
    Breakpoint.MD {
        Modifier.margin(top = 50.px)
    }
}

@Composable
fun StaggeringSolidButton(
    text: String,
    speed: Double = 0.1,
    isLight: Boolean = false,
    leadingIcon: (@Composable () -> Unit)? = null,
    trailingIcon: (@Composable () -> Unit)? = null,
    hoverDelay: Long = 100L,
    onClick: () -> Unit = {}
) {

    val letters = text.toCharArray().toList()
    val scope = rememberCoroutineScope()

    var isHovered by remember { mutableStateOf(false) }

    val staggerDelayMultiplier = 0.5 * speed

    Box(
        ScaleDownAnimation.toModifier()
            .onClick { onClick() }
            .onMouseEnter {
                scope.launch {
                    delay(hoverDelay)
                    isHovered = true
                }
            }
            .onMouseLeave {
                scope.launch {
                    delay(hoverDelay)
                    isHovered = false
                }
            }
            .borderRadius(20.px)
            .background(if (isLight) Colors.Black else Colors.White)
            .padding(leftRight = 20.px, topBottom = 8.px)
            .cursor(Cursor.Pointer)
            .transition(
                Transition.of(
                    property = TransitionProperty.All,
                    duration = 300.ms,
                    timingFunction = null,
                    delay = null
                )
            )
            .overflow(Overflow.Hidden),
        contentAlignment = Alignment.Center
    ) {

        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(6.px, alignment = Alignment.CenterHorizontally)
        ) {
            leadingIcon?.invoke()

            Box(
                modifier = Modifier.overflow(Overflow.Hidden)
            ) {
                Row(
                    Modifier
                        .transform { translateY(if (isHovered) (-40).px else 0.px) }
                        .transition(Transition.of("transform", 0.5.s, EaseInOut))
                        .thenIf(trailingIcon != null) {
                            Modifier.margin(right = 10.px)
                        }
                ) {
                    letters.forEachIndexed { index, letter ->
                        Box(
                            Modifier
                                .margin(right = 1.px) // Add spacing between letters
                                .transform { translateY(if (isHovered) (-10).px else 0.px) }
                                .transition(
                                    Transition.of(
                                        "transform",
                                        0.5.s,
                                        EaseInOut,
                                        (index * staggerDelayMultiplier).s
                                    )
                                )
                        ) {
                            SpanText(
                                text = letter.toString(),
                                modifier = Modifier
                                    .fontFamily(Constants.SYNE)
                                    .fontWeight(400)
                                    .fontSize(FontSize.Medium)
                                    .color(if (isLight) Colors.White else Colors.Black)
                            )
                        }
                    }
                }

                Row(
                    Modifier
                        .transform { translateY(if (isHovered) 0.px else 40.px) }
                        .transition(Transition.of("transform", 0.5.s, EaseInOut))
                ) {
                    letters.forEachIndexed { index, char ->
                        Box(
                            Modifier
                                .margin(right = 1.px)
                                .transform { translateY(if (isHovered) 0.px else 10.px) }
                                .transition(
                                    Transition.of(
                                        "transform",
                                        0.5.s,
                                        EaseInOut,
                                        (index * staggerDelayMultiplier).s
                                    )
                                )
                        ) {
                            SpanText(
                                text = char.toString(),
                                modifier = Modifier
                                    .fontFamily(Constants.SYNE)
                                    .fontWeight(400)
                                    .fontSize(FontSize.Medium)
                                    .color(if (isLight) Colors.White else Colors.Black)
                            )
                        }
                    }
                }
            }

            trailingIcon?.invoke()
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun StaggeringStrokeButton(
    text: String,
    speed: Double = 0.1,
    isLight: Boolean = false,
    leadingIcon: (@Composable () -> Unit)? = null,
    trailingIcon: (@Composable () -> Unit)? = null,
    onClick: () -> Unit = {}
) {

    var isHovered by remember { mutableStateOf(false) }

    val staggerDelayMultiplier = 0.5 * speed

    Box(
        ScaleDownAnimation.toModifier()
            .onClick { onClick() }
            .onMouseEnter { isHovered = true }
            .onMouseLeave { isHovered = false }
            .borderRadius(50.px)
            .border(1.px, LineStyle.Solid, if (isLight) Colors.Black else Colors.White)
            .padding(leftRight = 20.px, topBottom = 8.px)
            .cursor(Cursor.Pointer)
            .transition(
                Transition.of(
                    property = TransitionProperty.All,
                    duration = 300.ms,
                    timingFunction = null,
                    delay = null
                )
            )
            .overflow(Overflow.Hidden),
        contentAlignment = Alignment.Center
    ) {

        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            leadingIcon?.invoke()

            text.forEachIndexed { index, char ->
                Span(
                    attrs = Modifier
                        .styleModifier {
                            display(DisplayStyle.InlineBlock)
                            position(Position.Relative)
                            transform {
                                if (isHovered) {
                                    translateY((-10).px)
                                } else {
                                    translateY(0.px)
                                }
                            }
                            transition(
                                Transition.of("transform", staggerDelayMultiplier.s, EaseInOut, (index * 0.05).s)
                            )
                            margin(right = if (trailingIcon != null) 20.px else 0.px)
                        }
                        .toAttrs()
                ) {
                    SpanText(
                        text = char.toString(),
                        modifier = Modifier
                            .fontFamily(Constants.SYNE)
                            .fontWeight(400)
                            .margin(right = 10.px)
                            .color(if (isLight) Colors.White else Colors.Black)
                    )
                }
            }

            trailingIcon?.invoke()
        }
    }
}