package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.*
import com.binayshaw7777.myportfolio.utils.Constants
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.JustifyContent
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.css.functions.toImage
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

val BtnShineStyle = CssStyle.base {
    Modifier
        .position(Position.Absolute)
        .top(50.percent)
        .left(50.percent)
        .transform { translate((-50).percent, (-50).percent) }
        .padding(12.px, 48.px)
        .color(Color.rgb(0xffffff))
        .background(
            Background.of(
                image = linearGradient(LinearGradient.Direction.ToRight) {
                    add(Color.rgb(0x4d4d4d), 0.percent)
                    add(Color.rgb(0xffffff), 10.percent)
                    add(Color.rgb(0x4d4d4d), 20.percent)
                }.toImage()
            )
        )
        .backgroundPosition(BackgroundPosition.of(CSSPosition(0.px)))
        .styleModifier {
            property("-webkit-background-clip", "text")
            property("-webkit-text-fill-color", "transparent")
            property("-webkit-text-size-adjust", "none")
        }
        .animation(
            Shine.toAnimation(
                duration = 3.s,
                timingFunction = AnimationTimingFunction.Linear,
                iterationCount = AnimationIterationCount.Infinite,
                fillMode = AnimationFillMode.Forwards
            )
        )
        .fontWeight(600)
        .fontSize(16.px)
        .textDecorationLine(TextDecorationLine.None)
        .whiteSpace(WhiteSpace.NoWrap)
}
val Shine = Keyframes {
    0.percent {
        Modifier
            .backgroundPosition(BackgroundPosition.of(CSSPosition(0.px)))
    }
    60.percent {
        Modifier
            .backgroundPosition(BackgroundPosition.of(CSSPosition(180.px)))
    }
    100.percent {
        Modifier
            .backgroundPosition(BackgroundPosition.of(CSSPosition(180.px)))
    }
}


@Composable
fun FullScreenLoading(
    delay: Duration = 2.seconds,
    isLight: Boolean = false,
    content: @Composable () -> Unit
) {
    var isLoading by remember { mutableStateOf(true) }
    var progress by remember { mutableStateOf(0.percent) }

    LaunchedEffect(Unit) {
        delay(delay)
        isLoading = false
        progress = 100.percent
    }

    val backgroundColor = if (isLight) {
        Color.rgb(0xF5F5F5)
    } else Color.rgb(0x111414)

    val contentColor = if (isLight) {
        Colors.Black
    } else Colors.White

    if (isLoading) {
        Box(
            Modifier
                .fillMaxSize()
                .backgroundColor(backgroundColor)
                .display(DisplayStyle.Flex)
                .alignItems(AlignItems.Center)
                .justifyContent(JustifyContent.Center)
        ) {
            Column(
                Modifier
                    .alignItems(AlignItems.Start)
                    .gap(16.px)
            ) {
                SpanText(
                    text = "Loading ...",
                    modifier = BtnShineStyle
                        .toModifier()
                        .fontSize(24.px)
                        .fontFamily(Constants.INTER)
                )
            }
        }
    } else {
        content()
    }
}
