package com.binayshaw7777.myportfolio.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.css.pointerEvents
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun CircleCursor() {
    // State to track the circle's position
    var circleX by remember { mutableStateOf(0.0) }
    var circleY by remember { mutableStateOf(0.0) }

    // State to track the mouse position
    var mouseX by remember { mutableStateOf(0.0) }
    var mouseY by remember { mutableStateOf(0.0) }

    // Speed factor (0 = smoother, 1 = instant)
    val speed = 0.15

    // Track mouse movement
    DisposableEffect(Unit) {
        val mouseMoveListener = { e: Event ->
            val mouseEvent = e as MouseEvent
            mouseX = mouseEvent.clientX.toDouble()
            mouseY = mouseEvent.clientY.toDouble()
        }

        window.addEventListener("mousemove", mouseMoveListener)

        // Cleanup the event listener when the composable is disposed
        onDispose {
            window.removeEventListener("mousemove", mouseMoveListener)
        }
    }

    // Update circle position smoothly
    LaunchedEffect(Unit) {
        while (true) {
            circleX += (mouseX - circleX) * speed
            circleY += (mouseY - circleY) * speed
            delay(16) // ~60 FPS (16ms per frame)
        }
    }

    val color = if (ColorMode.current == ColorMode.LIGHT) Colors.Black else Colors.White

    // Render the circle
    Div(
        attrs = Modifier
            .classNames("circle")
            .styleModifier {
                position(Position.Fixed)
                width(20.px)
                height(20.px)
                border(1.px, LineStyle.Solid, color)
                borderRadius(50.percent)
                transform {
                    translateX(circleX.px - 5.px)
                    translateY(circleY.px - 5.px)
                }
                pointerEvents(PointerEvents.None)
            }
            .toAttrs()
    )
}