package com.binayshaw7777.myportfolio

import com.binayshaw7777.myportfolio.utils.Constants
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.grayscale
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.layout.HorizontalDividerStyle
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.after
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.palette.color
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import com.varabyte.kobweb.silk.theme.modifyStyleBase
import org.jetbrains.compose.web.css.*

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("body") {
        Modifier
            .fontFamily(
                "Inter",
                "Syne",
                "Darker Grotesque",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen",
                "Ubuntu",
                "Cantarell",
                "Fira Sans",
                "Droid Sans",
                "Helvetica Neue",
                "sans-serif"
            )
            .fontSize(18.px)
            .lineHeight(1.5)
    }

    // Silk dividers only extend 90% by default; we want full width dividers in our site
    ctx.theme.modifyStyleBase(HorizontalDividerStyle) {
        Modifier.fillMaxWidth()
    }
}

val HeadlineTextStyle = CssStyle {
    base {
        Modifier
            .fontSize(3.cssRem)
            .textAlign(TextAlign.Start)
            .lineHeight(1.2) //1.5x doesn't look as good on very large text
    }
}

val SubheadlineTextStyle = CssStyle {
    base {
        Modifier
            .fontSize(1.cssRem)
            .textAlign(TextAlign.Start)
            .color(colorMode.toPalette().color.toRgb().copyf(alpha = 0.8f))
    }
}

val CircleButtonVariant = ButtonStyle.addVariantBase {
    Modifier.padding(0.px).borderRadius(50.percent)
}

val UncoloredButtonVariant = ButtonStyle.addVariantBase {
    Modifier.setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
}

/**
 * Animations
 */

val Rotate = Keyframes {
    100.percent {
        Modifier
            .transform { rotate(1.turn) }
    }
}


val MarqueeStyle = CssStyle.base {
    Modifier
        .position(Position.Relative)
        .overflow(Overflow.Hidden)
        .minWidth(200.vw)
        .animation(
            MarqueeKeyFrames.toAnimation(
//                    colorMode, // TODO: Revisit this
                duration = 10.s,
                timingFunction = AnimationTimingFunction.Linear,
                iterationCount = AnimationIterationCount.Infinite
            )
        )
        .display(DisplayStyle.Flex)
        .justifyContent(com.varabyte.kobweb.compose.css.JustifyContent.SpaceBetween)
}


val MarqueeKeyFrames = Keyframes {
    0.percent {
        Modifier
            .transform { translateX(0.px) }
    }
    100.percent {
        Modifier
            .transform { translateX((-50).percent) }
    }
}


val UnderlineAnimationLightStyle = CssStyle {
    base {
        Modifier
            .padding(topBottom = 10.px)
            .position(Position.Relative)
    }
    after {
        Modifier
            .background(Colors.Black)
            .bottom(0.px)
            .content("")
            .display(DisplayStyle.Block)
            .height(2.px)
            .left(50.percent)
            .position(Position.Absolute)
            .transition(
                Transition.of("width", 0.3.s, TransitionTimingFunction.Ease, 0.s),
                Transition.of("left", 0.3.s, TransitionTimingFunction.Ease, 0.s)
            )
            .width(0.px)
    }
    cssRule(":hover:after") {
        Modifier
            .fillMaxWidth()
            .left(0.px)
    }
}

val UnderlineAnimationDarkStyle = CssStyle {
    base {
        Modifier
            .padding(topBottom = 10.px)
            .position(Position.Relative)
    }
    after {
        Modifier
            .background(Colors.White)
            .bottom(0.px)
            .content("")
            .display(DisplayStyle.Block)
            .height(2.px)
            .left(50.percent)
            .position(Position.Absolute)
            .transition(
                Transition.of("width", 0.3.s, TransitionTimingFunction.Ease, 0.s),
                Transition.of("left", 0.3.s, TransitionTimingFunction.Ease, 0.s)
            )
            .width(0.px)
    }
    cssRule(":hover:after") {
        Modifier
            .fillMaxWidth()
            .left(0.px)
    }
}

val ButtonStyle = CssStyle {
    base {
        Modifier.padding(leftRight = 20.px)
            .margin(top = 30.px)
            .transition(
                Transition.of(
                    property = TransitionProperty.All,
                    duration = 300.ms,
                    timingFunction = null,
                    delay = null
                )
            )
    }
    hover {
        Modifier.padding(leftRight = 30.px)
    }
    Breakpoint.SM {
        Modifier.margin(top = 40.px)
    }
    Breakpoint.MD {
        Modifier.margin(top = 50.px)
    }
}

val HoverableTextStyle = CssStyle {
    base {
        Modifier
            .color(
                when (colorMode) {
                    ColorMode.LIGHT -> Color.rgb(0x111414)
                    ColorMode.DARK -> Color.rgb(0xF5F5F5)
                }
            )
            .opacity(0.4f)
    }
    hover {
        Modifier
            .opacity(0.8f)
    }
}

val JusTapDivStyle = CssStyle {
    cssRule("#justap") {
        return@cssRule Modifier
            .display(DisplayStyle.None)
            .opacity(0)
            .animation(
                SlideInOut.toAnimation(
//                colorMode,
                    duration = 0.5.s, fillMode = AnimationFillMode.Forwards
                )
            )
    }
    cssRule(":hover #justap") {
        return@cssRule Modifier
            .display(DisplayStyle.Block)
            .animation(
                SlideIn.toAnimation(
//                colorMode,
                    duration = 0.5.s, fillMode = AnimationFillMode.Forwards
                )
            )
    }
}

val ReadBudDivStyle = CssStyle {
    cssRule("#readbud") {
        return@cssRule Modifier
            .display(DisplayStyle.None)
            .opacity(0)
            .animation(
                SlideInOut.toAnimation(
//                colorMode,
                    duration = 0.5.s, fillMode = AnimationFillMode.Forwards
                )
            )
    }
    cssRule(":hover #readbud") {
        return@cssRule Modifier
            .display(DisplayStyle.Block)
            .animation(
                SlideIn.toAnimation(
//                colorMode,
                    duration = 0.5.s, fillMode = AnimationFillMode.Forwards
                )
            )
    }
}


val SlideInOut = Keyframes {
    0.percent {
        Modifier
            .opacity(0)
            .transform { translateX(100.percent) }
    }
    100.percent {
        Modifier
            .opacity(1)
            .transform { translateX(0.percent) }
    }
}
val SlideIn = Keyframes {
    0.percent {
        Modifier
            .opacity(0)
            .transform { translateX(100.percent) }
    }
    100.percent {
        Modifier
            .opacity(1)
            .transform { translateX(0.percent) }
    }
}


val GrayscaleHoverElementStyle = CssStyle {
    hover {
        return@hover Modifier
            .animation(
                GrayscaleIn.toAnimation(
//                colorMode,
                    duration = 0.5.s, fillMode = AnimationFillMode.Forwards
                )
            )
    }

    cssRule(":not(:hover)") {
        return@cssRule Modifier
            .animation(
                GrayscaleOut.toAnimation(
//                colorMode,
                    duration = 0.5.s, fillMode = AnimationFillMode.Forwards
                )
            )
    }
}
val GrayscaleIn = Keyframes {
    from {
        Modifier
            .filter(grayscale(0.percent))
    }
    to {
        Modifier
            .filter(grayscale(100.percent))
    }
}
val GrayscaleOut = Keyframes {
    from {
        Modifier
            .filter(grayscale(100.percent))
    }
    to {
        Modifier
            .filter(grayscale(0.percent))
    }
}

val GrowStyle = CssStyle {
    base {
        Modifier
            .transition(Transition.of("all", 0.2.s, TransitionTimingFunction.EaseInOut, null))
    }
    hover {
        Modifier
            .transform { scale(1.01) }
    }
}


val TextAnimationKeyframes = Keyframes {
    0.percent {
        Modifier
            .margin(top = 50.px)
            .opacity(0)
    }
    100.percent {
        Modifier
            .margin(top = 0.px)
            .opacity(1)
    }
}


// HERO


val HeroSectionLayoutStyle = CssStyle {
    base {
        Modifier.fillMaxWidth().minHeight(50.vh)
    }
    Breakpoint.SM {
        Modifier.fillMaxWidth().minHeight(60.vh)
    }
    Breakpoint.MD {
        Modifier.fillMaxWidth().minHeight(80.vh)
    }
}

val HeroSectionFontStyle = CssStyle {
    base {
        Modifier
            .fontSize(35.px)
            .fontFamily(Constants.SYNE)
            .fontOpticalSizing(FontOpticalSizing.Auto)
            .fontStyle(FontStyle.Normal)
            .fontWeight(700)
            .lineHeight(1.2)
    }
    Breakpoint.SM {
        Modifier
            .fontSize(60.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(100.px)
    }
}

val SectionTitleFontStyle = CssStyle {
    base {
        Modifier.fontFamily(Constants.INTER)
            .fontSize(2.5.cssRem).maxWidth(100.vw).fontWeight(FontWeight.Black)
            .margin(bottom = 0.5.cssRem)
    }
    Breakpoint.SM {
        Modifier.fontSize(3.5.cssRem)
            .margin(bottom = 0.75.cssRem)
    }
    Breakpoint.MD {
        Modifier.fontSize(5.cssRem)
            .margin(bottom = 1.cssRem)
    }
}


// Experience Styles

val ExperienceDurationFontStyle = CssStyle {
    base {
        Modifier.fontFamily(Constants.DARKER_GROTESQUE)
            .opacity(0.8f)
            .fontWeight(700)
            .fontSize(1.cssRem)
    }

    Breakpoint.SM {
        Modifier.fontSize(1.4.cssRem)
    }

    Breakpoint.MD {
        Modifier.fontSize(1.75.cssRem)
    }
}

val ExperienceOrganizationFontStyle = CssStyle {
    base {
        Modifier.fontSize(1.cssRem).fontWeight(FontWeight.Bold)
    }

    Breakpoint.SM {
        Modifier.fontSize(1.5.cssRem)
    }

    Breakpoint.MD {
        Modifier.fontSize(2.cssRem)
    }
}

// About Me Styles

val AboutMeFontStyle = CssStyle {
    base {
        Modifier
            .fontFamily(Constants.SYNE)
            .fontSize(16.px)
            .fontWeight(600)
            .margin(topBottom = 5.px)
    }
    Breakpoint.SM {
        Modifier
            .fontSize(18.px)
            .margin(topBottom = 10.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(20.px)
            .margin(topBottom = 20.px)
    }
    Breakpoint.LG {
        Modifier
            .fontSize(22.px)
    }
}


val ExperienceRoleFontStyle = CssStyle {
    base {
        Modifier.fontSize(1.cssRem)
            .fontFamily(Constants.DARKER_GROTESQUE)
            .opacity(0.5f)
            .fontWeight(600)
    }

    Breakpoint.SM {
        Modifier.fontSize(1.1.cssRem)
    }

    Breakpoint.MD {
        Modifier.fontSize(1.25.cssRem)
    }
}

// Footer Styles

val FooterStyle = CssStyle.base {
    Modifier.padding(topBottom = 1.5.cssRem, leftRight = 10.percent)
}