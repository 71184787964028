package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.Composable
import com.binayshaw7777.myportfolio.JusTapDivStyle
import com.binayshaw7777.myportfolio.ReadBudDivStyle
import com.binayshaw7777.myportfolio.SectionTitleFontStyle
import com.binayshaw7777.myportfolio.utils.Constants
import com.binayshaw7777.myportfolio.utils.Res
import com.binayshaw7777.myportfolio.utils.Strings
import com.binayshaw7777.myportfolio.widgets.AppearanceAwareImage
import com.binayshaw7777.myportfolio.widgets.StrokeChips
import com.varabyte.kobweb.compose.css.FontOpticalSizing
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontOpticalSizing
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun Project() {

    val pageContext = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier.fillMaxSize().fontFamily(Constants.SYNE).margin(top = 100.px).id("projects"),
    ) {

        SpanText(
            text = "Works",
            modifier = SectionTitleFontStyle.toModifier()
        )

        HorizontalDivider()

        Row(
            modifier = JusTapDivStyle.toModifier().fillMaxWidth()
                .margin(topBottom = 75.px)
                .onClick {
                    pageContext.router.navigateTo(Constants.JUSTAP_URL)
                },
            horizontalArrangement = Arrangement.SpaceAround,
            verticalAlignment = Alignment.CenterVertically
        ) {

            Column(
                modifier = Modifier.maxWidth(75.percent)
                    .margin(topBottom = 20.px, leftRight = 15.px),
                verticalArrangement = Arrangement.SpaceAround
            ) {
                SpanText(
                    text = Strings.JUSTAP,
                    modifier = Modifier.fontSize(60.px).fontWeight(FontWeight.Bold)
                )
                SpanText(
                    text = Strings.JUSTAP_DESCRIPTION,
                    modifier = Modifier
                        .fontSize(18.px)
                        .margin(topBottom = 10.px)
                        .fontWeight(401)
                        .fontOpticalSizing(FontOpticalSizing.Auto)
                )

                Row(
                    modifier = Modifier.margin(top = 2.cssRem)
                        .display(DisplayStyle.Flex).flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    StrokeChips(
                        modifier = Modifier.margin(
                            right = 0.8.cssRem,
                            top = 0.4.cssRem,
                            bottom = 0.4.cssRem
                        ),
                        text = Strings.KOTLIN
                    )
                    StrokeChips(
                        modifier = Modifier.margin(
                            right = 0.8.cssRem,
                            top = 0.4.cssRem,
                            bottom = 0.4.cssRem
                        ),
                        text = Strings.JETPACK_COMPOSE
                    )
                    StrokeChips(
                        modifier = Modifier.margin(
                            right = 0.8.cssRem,
                            top = 0.4.cssRem,
                            bottom = 0.4.cssRem
                        ),
                        text = Strings.FIREBASE
                    )
                }
            }

            if (breakpoint >= Breakpoint.MD) {
                Spacer()

                SpanText("/01", modifier = Modifier.fontSize(40.px).fontWeight(FontWeight.Bold))

                Div(attrs = Modifier.id("justap").toAttrs()) {
                    AppearanceAwareImage(
                        src = Res.CURVED_HEAD_ARROW,
                        modifier = Modifier.size(40.px).margin(all = 20.px)
                    )
                }
            }
        }

        HorizontalDivider()

        Row(
            modifier = ReadBudDivStyle.toModifier().fillMaxWidth()
                .margin(topBottom = 75.px)
                .onClick {
                    pageContext.router.navigateTo(Constants.READBUD_URL)
                },
            horizontalArrangement = Arrangement.SpaceAround,
            verticalAlignment = Alignment.CenterVertically
        ) {

            Column(
                modifier = Modifier.maxWidth(75.percent)
                    .margin(topBottom = 20.px, leftRight = 15.px),
                verticalArrangement = Arrangement.SpaceAround
            ) {
                SpanText(
                    text = Strings.READBUD,
                    modifier = Modifier.fontSize(60.px).fontWeight(FontWeight.Bold)
                )
                SpanText(
                    text = Strings.READBUD_DESCRIPTION,
                    modifier = Modifier
                        .fontSize(18.px)
                        .margin(topBottom = 10.px)
                        .fontWeight(401)
                        .fontOpticalSizing(FontOpticalSizing.Auto)
                )

                Row(
                    modifier = Modifier.margin(top = 2.cssRem)
                        .display(DisplayStyle.Flex).flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    StrokeChips(
                        modifier = Modifier.margin(
                            right = 0.8.cssRem,
                            top = 0.4.cssRem,
                            bottom = 0.4.cssRem
                        ),
                        text = Strings.KOTLIN
                    )
                    StrokeChips(
                        modifier = Modifier.margin(
                            right = 0.8.cssRem,
                            top = 0.4.cssRem,
                            bottom = 0.4.cssRem
                        ),
                        text = Strings.JETPACK_COMPOSE
                    )
                    StrokeChips(
                        modifier = Modifier.margin(
                            right = 0.8.cssRem,
                            top = 0.4.cssRem,
                            bottom = 0.4.cssRem
                        ),
                        text = Strings.ML_KIT
                    )
                }
            }

            if (breakpoint >= Breakpoint.MD) {
                Spacer()

                SpanText("/02", modifier = Modifier.fontSize(40.px).fontWeight(FontWeight.Bold))

                Div(attrs = Modifier.id("readbud").toAttrs()) {
                    AppearanceAwareImage(
                        src = Res.CURVED_HEAD_ARROW,
                        modifier = Modifier.size(40.px).margin(all = 20.px)
                    )
                }
            }
        }

        HorizontalDivider()
    }

}