package com.binayshaw7777.myportfolio.components.sections

import androidx.compose.runtime.*
import com.binayshaw7777.myportfolio.FooterStyle
import com.binayshaw7777.myportfolio.HoverableTextStyle
import com.binayshaw7777.myportfolio.components.widgets.SocialMediaIcons
import com.binayshaw7777.myportfolio.utils.Strings
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import kotlin.js.Date


@Composable
fun Footer(modifier: Modifier = Modifier) {

    val pageContext = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    var year by remember { mutableStateOf("2025") }

    LaunchedEffect(Unit) {
        val timestamp = Date.now()
        val currentDate = Date(timestamp)
        val currentYear = currentDate.getFullYear()
        year = currentYear.toString()
    }

    val bottomColumnAlignment = if (breakpoint >= Breakpoint.MD) {
        Alignment.End
    } else {
        Alignment.Start
    }

    Box(FooterStyle.toModifier().then(modifier), contentAlignment = Alignment.Center) {

        SimpleGrid(
            modifier = Modifier.fillMaxWidth().padding(topBottom = 1.5.cssRem),
            numColumns = numColumns(base = 1, sm = 1, md = 2)
        ) {
            Column(
                modifier = Modifier.fillMaxWidth().margin(topBottom = 5.px),
                verticalArrangement = Arrangement.Center
            ) {
                SpanText(
                    text = "${Strings.COPYRIGHT} $year ${Strings.BINAY_SHAW}",
                    modifier = Modifier
                        .textAlign(TextAlign.Center)
                        .opacity(0.4f)
                        .margin(bottom = 0.8.cssRem)
                )
                SocialMediaIcons()
            }

            Column(
                modifier = Modifier.margin(topBottom = 10.px),
                horizontalAlignment = bottomColumnAlignment,
                verticalArrangement = Arrangement.Center
            ) {
                Column(
                    horizontalAlignment = Alignment.Start
                ) {
                    SpanText(
                        text = Strings.BANGALORE_INDIA,
                        modifier = Modifier
                            .textAlign(TextAlign.Center)
                            .opacity(0.4f)
                            .margin(bottom = 0.8.cssRem)
                    )
                    SpanText(
                        text = Strings.MY_MAIL,
                        modifier = HoverableTextStyle.toModifier()
                            .textAlign(TextAlign.Center)
                            .onClick {
                                pageContext.router.navigateTo("mailto:binayshaw7777@gmail.com")
                            }
                    )
                }
            }
        }
    }
}