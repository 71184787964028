import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "MyPortfolio"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.binayshaw7777.myportfolio.pages.HomePage() }
        ctx.router.register("/about") { com.binayshaw7777.myportfolio.pages.AboutPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", com.binayshaw7777.myportfolio.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text",
                com.binayshaw7777.myportfolio.SubheadlineTextStyle)
        ctx.theme.registerStyle("marquee", com.binayshaw7777.myportfolio.MarqueeStyle)
        ctx.theme.registerStyle("underline-animation-light",
                com.binayshaw7777.myportfolio.UnderlineAnimationLightStyle)
        ctx.theme.registerStyle("underline-animation-dark",
                com.binayshaw7777.myportfolio.UnderlineAnimationDarkStyle)
        ctx.theme.registerStyle("button", com.binayshaw7777.myportfolio.ButtonStyle)
        ctx.theme.registerStyle("hoverable-text", com.binayshaw7777.myportfolio.HoverableTextStyle)
        ctx.theme.registerStyle("jus-tap-div", com.binayshaw7777.myportfolio.JusTapDivStyle)
        ctx.theme.registerStyle("read-bud-div", com.binayshaw7777.myportfolio.ReadBudDivStyle)
        ctx.theme.registerStyle("grayscale-hover-element",
                com.binayshaw7777.myportfolio.GrayscaleHoverElementStyle)
        ctx.theme.registerStyle("grow", com.binayshaw7777.myportfolio.GrowStyle)
        ctx.theme.registerStyle("hero-section-layout",
                com.binayshaw7777.myportfolio.HeroSectionLayoutStyle)
        ctx.theme.registerStyle("hero-section-font",
                com.binayshaw7777.myportfolio.HeroSectionFontStyle)
        ctx.theme.registerStyle("section-title-font",
                com.binayshaw7777.myportfolio.SectionTitleFontStyle)
        ctx.theme.registerStyle("experience-duration-font",
                com.binayshaw7777.myportfolio.ExperienceDurationFontStyle)
        ctx.theme.registerStyle("experience-organization-font",
                com.binayshaw7777.myportfolio.ExperienceOrganizationFontStyle)
        ctx.theme.registerStyle("about-me-font", com.binayshaw7777.myportfolio.AboutMeFontStyle)
        ctx.theme.registerStyle("experience-role-font",
                com.binayshaw7777.myportfolio.ExperienceRoleFontStyle)
        ctx.theme.registerStyle("footer", com.binayshaw7777.myportfolio.FooterStyle)
        ctx.theme.registerStyle("markdown",
                com.binayshaw7777.myportfolio.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content",
                com.binayshaw7777.myportfolio.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("btn-shine",
                com.binayshaw7777.myportfolio.components.sections.BtnShineStyle)
        ctx.theme.registerStyle("nav-header",
                com.binayshaw7777.myportfolio.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("arrow-up",
                com.binayshaw7777.myportfolio.components.widgets.ArrowUpStyle)
        ctx.theme.registerStyle("back-to-top-button",
                com.binayshaw7777.myportfolio.components.widgets.BackToTopButtonStyle)
        ctx.theme.registerStyle("scroll-down-animation",
                com.binayshaw7777.myportfolio.components.widgets.ScrollDownAnimationStyle)
        ctx.theme.registerStyle("circular-stroke-image",
                com.binayshaw7777.myportfolio.widgets.CircularStrokeImageStyle)
        ctx.theme.registerStyle("scale-down-animation",
                com.binayshaw7777.myportfolio.widgets.ScaleDownAnimation)
        ctx.theme.registerVariant("-circle", com.binayshaw7777.myportfolio.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", com.binayshaw7777.myportfolio.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("rotate", com.binayshaw7777.myportfolio.Rotate)
        ctx.theme.registerKeyframes("marquee-key-frames",
                com.binayshaw7777.myportfolio.MarqueeKeyFrames)
        ctx.theme.registerKeyframes("slide-in-out", com.binayshaw7777.myportfolio.SlideInOut)
        ctx.theme.registerKeyframes("slide-in", com.binayshaw7777.myportfolio.SlideIn)
        ctx.theme.registerKeyframes("grayscale-in", com.binayshaw7777.myportfolio.GrayscaleIn)
        ctx.theme.registerKeyframes("grayscale-out", com.binayshaw7777.myportfolio.GrayscaleOut)
        ctx.theme.registerKeyframes("text-animation",
                com.binayshaw7777.myportfolio.TextAnimationKeyframes)
        ctx.theme.registerKeyframes("shine",
                com.binayshaw7777.myportfolio.components.sections.Shine)
        ctx.theme.registerKeyframes("fade-in-key-frames",
                com.binayshaw7777.myportfolio.components.widgets.FadeInKeyFrames)
        ctx.theme.registerKeyframes("move", com.binayshaw7777.myportfolio.components.widgets.Move)
        com.binayshaw7777.myportfolio.initColorMode(ctx)
        com.binayshaw7777.myportfolio.initSiteStyles(ctx)
        com.binayshaw7777.myportfolio.initTheme(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        com.binayshaw7777.myportfolio.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
