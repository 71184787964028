package com.binayshaw7777.myportfolio.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.VerticalAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scale
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.verticalAlign
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CircularStrokeImage(
    src: String,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = CircularStrokeImageStyle
            .toModifier()
            .border(1.px, LineStyle.Solid, Colors.Gray)
            .borderRadius(50.percent)
            .textAlign(TextAlign.Center)
            .lineHeight(50.px)
            .padding(8.px)
            .display(DisplayStyle.Flex)
            .verticalAlign(VerticalAlign.Middle)
            .then(modifier),
        contentAlignment = Alignment.Center
    ) {
        AppearanceAwareImage(
            src = src,
            modifier = Modifier.size(24.px)
        )
    }
}

val CircularStrokeImageStyle = CssStyle {
    base {
        Modifier.opacity(0.8f)
    }
    hover {
        Modifier.scale(1.04).opacity(1f)
    }
}